
html, body {
  width: 100%;
  height: 100%;
  background-color: rgb(256, 254, 228);
}
.app{
  color:rgb(67, 146, 79);
  text-align: center;
    /* display: table; */
    justify-content: space-between;
  flex-direction: row;
  margin-right: auto;
  margin-left: auto;
}
.main-container{
  background-color:  rgb(153, 195, 85);
  color: rgb(255, 255, 255);
  

}
.container-all{
  font-weight: bold;
}






